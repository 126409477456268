<template>
  <VContainer fluid>
    <VForm
      ref="form"
      v-model="valid"
      @submit.prevent="submit"
    >
      <header>
        <img
          class="logo"
          :src="(vendor.branding && vendor.branding.logo) ? `/${vendor.branding.logo}` : logotype"
          :alt="vendor.title"
          aria-hidden="true"
        >
        <h1 class="page-title">
          {{ localDocumentTitle }}
        </h1>
      </header>

      <VTextField
        v-model="username"
        outlined
        persistent-hint
        :label="$t('usernames.one')"
        autofocus
        name="username"
        :rules="[
          v => !!v || 'Это поле обязательно',
          v => v.length <= 254 || 'Логин должен быть не больше 254 символов'
        ]"
        maxlength="254"
        autocomplete="username nickname"
        required
      />

      <VTextField
        v-model="title"
        outlined
        persistent-hint
        label="ФИО или название организации"
        name="title"
        :rules="[
          v => !!v || 'Это поле обязательно',
          v => v.length <= 1023 || 'Логин должен быть не больше 1023 символов'
        ]"
        maxlength="1023"
        autocomplete="organization name"
        required
      />

      <VTextField
        v-model="email"
        outlined
        persistent-hint
        :label="$t('anonymous_your_email.one')"
        name="email"
        :rules="[
          v => !!v || 'Это поле обязательно',
          v => v.length <= 254 || 'Логин должен быть не больше 254 символов',
          v => /.+@.+/.test(v) || 'Невалидный email'
        ]"
        type="email"
        maxlength="254"
        autocomplete="email"
        required
      />

      <VTextField
        v-model="phone"
        v-mask="phoneMask"
        outlined
        persistent-hint
        :label="$t('phones.one')"
        type="tel"
        name="phone"
        :rules="[
          v => !!v || 'Это поле обязательно',
        ]"
        maxlength="254"
        autocomplete="tel"
        required
      />

      <VBtn
        x-large
        type="submit"
        block
        color="primary"
        :loading="isSubmitting"
      >
        {{ $t('anonymous_register.one') }}
      </VBtn>
      <footer class="text-center">
        <VCardText>
          Уже есть аккаунт? <RouterLink to="/login">
            {{ $t('anonymous_sign_in.one') }}
          </RouterLink>
        </VCardText>
        <VDivider />
        <VCardText>
          Нажимая на кнопку «Зарегистрироваться», вы соглашаетесь с <a
            href="/privacy-policy"
            target="_blank"
            rel="noopener noreferrer"
          >политикой обработки персональных данных.</a>
        </VCardText>
      </footer>
    </VForm>
  </VContainer>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { mask } from 'vue-the-mask'

import documentTitle from '../mixins/documentTitle'
import logotype from '../assets/images/uchi.svg'
import * as actions from '@/store/actions/types'
import * as getters from '@/store/getters/types'

export default {
  name: 'TheAccountRegister',
  directives: { mask },
  mixins: [documentTitle],

  data () {
    return {
      documentTitle: 'anonymous_register.one',
      valid: false,
      username: '',
      title: '',
      email: '',
      phone: '',
      phoneMask: '+###############',
      logotype,
      isSubmitting: false
    }
  },

  computed: mapGetters({
    vendor: getters.VENDOR
  }),

  methods: {
    ...mapActions({
      registerAccount: actions.REGISTER
    }),

    async submit () {
      const form = this.$refs.form
      if (!form.validate()) { return }
      this.isSubmitting = true

      this.registerAccount(new FormData(form.$el))
      this.isSubmitting = false
    }
  }
}
</script>
