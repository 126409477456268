var render = function render(){var _vm=this,_c=_vm._self._c;return _c('VContainer',{attrs:{"fluid":""}},[_c('VForm',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('header',[_c('img',{staticClass:"logo",attrs:{"src":(_vm.vendor.branding && _vm.vendor.branding.logo) ? `/${_vm.vendor.branding.logo}` : _vm.logotype,"alt":_vm.vendor.title,"aria-hidden":"true"}}),_c('h1',{staticClass:"page-title"},[_vm._v(" "+_vm._s(_vm.localDocumentTitle)+" ")])]),_c('VTextField',{attrs:{"outlined":"","persistent-hint":"","label":_vm.$t('usernames.one'),"autofocus":"","name":"username","rules":[
        v => !!v || 'Это поле обязательно',
        v => v.length <= 254 || 'Логин должен быть не больше 254 символов'
      ],"maxlength":"254","autocomplete":"username nickname","required":""},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}}),_c('VTextField',{attrs:{"outlined":"","persistent-hint":"","label":"ФИО или название организации","name":"title","rules":[
        v => !!v || 'Это поле обязательно',
        v => v.length <= 1023 || 'Логин должен быть не больше 1023 символов'
      ],"maxlength":"1023","autocomplete":"organization name","required":""},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}}),_c('VTextField',{attrs:{"outlined":"","persistent-hint":"","label":_vm.$t('anonymous_your_email.one'),"name":"email","rules":[
        v => !!v || 'Это поле обязательно',
        v => v.length <= 254 || 'Логин должен быть не больше 254 символов',
        v => /.+@.+/.test(v) || 'Невалидный email'
      ],"type":"email","maxlength":"254","autocomplete":"email","required":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('VTextField',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.phoneMask),expression:"phoneMask"}],attrs:{"outlined":"","persistent-hint":"","label":_vm.$t('phones.one'),"type":"tel","name":"phone","rules":[
        v => !!v || 'Это поле обязательно',
      ],"maxlength":"254","autocomplete":"tel","required":""},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}}),_c('VBtn',{attrs:{"x-large":"","type":"submit","block":"","color":"primary","loading":_vm.isSubmitting}},[_vm._v(" "+_vm._s(_vm.$t('anonymous_register.one'))+" ")]),_c('footer',{staticClass:"text-center"},[_c('VCardText',[_vm._v(" Уже есть аккаунт? "),_c('RouterLink',{attrs:{"to":"/login"}},[_vm._v(" "+_vm._s(_vm.$t('anonymous_sign_in.one'))+" ")])],1),_c('VDivider'),_c('VCardText',[_vm._v(" Нажимая на кнопку «Зарегистрироваться», вы соглашаетесь с "),_c('a',{attrs:{"href":"/privacy-policy","target":"_blank","rel":"noopener noreferrer"}},[_vm._v("политикой обработки персональных данных.")])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }